import axios from 'axios';
import {
    useInfiniteQuery,
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import { z } from 'zod';
import { err, ok } from 'neverthrow';
import { useSnackbar } from 'notistack';
import fileDownload from 'js-file-download';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { entries } from 'lodash';
import superjson from 'superjson';
import { useRouter } from 'next/router';

import { logger } from '../common/utils/logger';
import { getUrlWithParamsAndQueries } from '../common/utils/getUrlWithParamsAndQueries';
import { useSelectedRestaurantId } from '../restaurant-admin/hooks/useSelectedRestaurantId';

import { createInlineError, QerkoError } from './shared/errors';
import { useLanguageContext } from './localization/localization';
import { useAppContext } from './appState';

import type { Uuid } from 'src/shared/types';
import type { UseInfiniteQueryResult } from '@tanstack/react-query/src/types';
import type { EmptyObject, Simplify } from 'type-fest';
import type {
    QueryKey,
    UseMutationOptions, UseMutationResult,
    UseQueryOptions,
    UseQueryResult } from '@tanstack/react-query';
import type { IsUnknown } from 'ts-essentials';
import type { APIv1Qr } from './shared-interface/ApiQrInterface';
import type { APIv1RestaurantAdmin } from './shared-interface/ApiRestaurantAdminInterface';
import type { Auth } from './appState';
import type { Result } from 'neverthrow';
import type { QerkoErrorExtraValue } from './shared/errors';

interface Response<TData> {
    statusCode: number;
    body: TData;
}

interface RequestOptions<TValidator, TBody extends Record<string, unknown> | undefined | BodyInit = undefined> {
    validator?: TValidator;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    body?: TBody;
    headers?: Record<string, string>;
    lang?: string;
}

export class ApiClientRequestFailedError extends QerkoError {

    protected __prop: any;

}

export class ApiClientRequestCanceledError extends QerkoError {

    protected __prop: any;

}

export class ApiClientInvalidResponseDataError extends QerkoError {

    protected __prop: any;

    public constructor(
        public readonly response: QerkoErrorExtraValue,
        public readonly zodError: z.ZodError,
        public readonly url: string,
    ) {
        super('Invalid response structure', zodError);
    }

    getExtra(): QerkoErrorExtraValue {
        return {
            response: this.response,
            url: this.url,
            zodError: this.zodError.errors.map(({ message, path, code }) => ({ code, message, path })),
        };
    }

}

export const PaginationSchema = z.object({
    limit: z.number(),
    offset: z.number(),
    total: z.number(),
});

export const GenerateSchema = <T extends z.ZodType<any, any>, TNumber extends number>(body: T, statusCode: TNumber) => z.object({
    body,
    statusCode: z.literal(statusCode),
});

export const GeneratePaginationSchema = <T extends z.ZodType<any, any>, TNumber extends number>(body: T, statusCode: TNumber) => GenerateSchema(z.object({
    data: body,
    pagination: PaginationSchema,
}), statusCode);

export const GenerateErrorSchema = <TNumber extends number>(statusCode: TNumber) => z.object({
    body: z.object({
        message: z.string(),
        reason: z.string().nullable().optional(),
    }),
    pagination: z.undefined(),
    statusCode: z.literal(statusCode),
});

export const doRequest = async <
    TValidator extends z.ZodTypeAny = z.ZodUndefined,
    TBody extends Record<string, unknown> | undefined | BodyInit = undefined,
>(
    apiUrl: string,
    path: string,
    {
        method = 'GET',
        validator,
        body,
        headers = {},
        signal,
    }: RequestOptions<TValidator, TBody> & { signal?: AbortSignal } = { headers: {}, method: 'GET' }
): Promise<Result<Response<z.infer<TValidator>>, ApiClientRequestFailedError | ApiClientInvalidResponseDataError | ApiClientRequestCanceledError>> => {
    try {
        const response = await axios.request({
            data: body,
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            method,
            signal,
            url: `${apiUrl}${path}`,
            validateStatus: () => true,
        });

        const responseData = response.data.json !== undefined
            ? superjson.deserialize(response.data)
            : response.data;

        const result = {
            body: responseData,
            statusCode: response.status,
        };

        if (validator === undefined) {
            return ok(result);
        }
        try {
            return ok(await validator.parseAsync(result));
        } catch (e: unknown) {
            if (e instanceof z.ZodError) {
                return err(new ApiClientInvalidResponseDataError(result, e, `${apiUrl}${path}`));
            }

            throw e;
        }

    } catch (e: unknown) {
        if (axios.isCancel(e)) {
            return err(new ApiClientRequestCanceledError('Request has been canceled'));
        }

        if (!(e instanceof Error)) {
            throw e;
        }

        return err(new ApiClientRequestFailedError(e.message, e));
    }
};

export const doQerkoRequest = async <TValidator extends z.ZodTypeAny = z.ZodUndefined, TBody extends Record<string, unknown> | FormData | undefined = undefined>(
    auth: Auth | null,
    restaurantId: Uuid | null,
// setAuth: (auth: Auth) => unknown,
    apiUrl: string,
    path: (context: {
        auth: Auth | null;
        body: TBody;
    }) => string | null,
    {
        method = 'GET',
        lang,
        validator,
        body,
        headers = {},
        signal,
    }: RequestOptions<TValidator, TBody> & { signal?: AbortSignal }): Promise<Result<Response<z.infer<TValidator>>, ApiClientRequestFailedError | ApiClientInvalidResponseDataError | ApiClientRequestCanceledError>> => {

    if (typeof body === 'object' && body !== null && '_accessToken' in body) {
        headers.Authorization = `Bearer ${body._accessToken}`;
        delete body._accessToken;
    } else if (auth !== null) {
        headers.Authorization = `Bearer ${auth.accessToken}`;
    }

    if (restaurantId !== null) {
        headers['id-restaurant'] = restaurantId;
    }

    if (lang) {
        headers['accept-language'] = lang;
    }

    const url = path({
        auth,
        body: body as TBody,
    });
    if (url !== null) {
        return doRequest(apiUrl, url, {
            body,
            headers,
            method,
            signal,
            validator,
        });
    }

    throw new Error('Unauthorized request');
};

type QerkoDownload = (url: string, fileName: string) => Promise<void>;
type QerkoPostDownload = (url: string, data: Record<string, unknown>, fileName: string) => Promise<void>;

export const useQerkoDownload = (): QerkoDownload => {
    const { apiUrl, auth } = useAppContext();
    const [, selectedRestaurantId] = useSelectedRestaurantId();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');
    return useMemo(() => {
        const headers: Record<string, string> = {
            Accept: 'application/pdf',
        };
        if (auth !== null) {
            headers.Authorization = `Bearer ${auth.accessToken}`;
        }
        if (selectedRestaurantId !== null) {
            headers['id-restaurant'] = selectedRestaurantId;
        }
        return async (url: string, fileName: string): Promise<void> => {
            const response = await axios.get(`${apiUrl}${url}`, {
                headers,
                responseType: 'blob',
                validateStatus: () => true,
            });
            if (response.status === 200) {
                fileDownload(response.data, fileName);
                return;
            }
            // we want to show message from server !
            if (response.status >= 300) {
                let errorMessage = t('error.message.unexpected');
                try {
                    const data = await (response.data as Blob).text();
                    const parsedData = JSON.parse(data);
                    errorMessage = parsedData.message ?? errorMessage;
                } catch (e: unknown) {
                    // eslint-disable-next-line no-console,no-restricted-globals
                    console.error(e);
                }
                // Default message is for unexpected returns. For example from load balancer etc...
                enqueueSnackbar(errorMessage, { variant: 'error' });
                return;
            }
        };
    }, [apiUrl, auth, enqueueSnackbar, selectedRestaurantId, t]);
};

export const useQerkoPostDownload = (): QerkoPostDownload => {
    const { apiUrl, auth } = useAppContext();
    const [, selectedRestaurantId] = useSelectedRestaurantId();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');
    return useMemo(() => {
        const headers: Record<string, string> = {
            Accept: 'application/pdf',
        };
        if (auth !== null) {
            headers.Authorization = `Bearer ${auth.accessToken}`;
        }
        if (selectedRestaurantId !== null) {
            headers['id-restaurant'] = selectedRestaurantId;
        }
        return async (url: string, data: Record<string, unknown>, fileName: string): Promise<void> => {
            const response = await axios.post(`${apiUrl}${url}`, data, {
                headers,
                responseType: 'blob',
                validateStatus: () => true,
            });
            if (response.status === 200) {
                fileDownload(response.data, fileName);
                return;
            }
            // we want to show message from server !
            if (response.status >= 300) {
                let errorMessage = t('error.message.unexpected');
                try {
                    const data = await (response.data as Blob).text();
                    const parsedData = JSON.parse(data);
                    errorMessage = parsedData.message ?? errorMessage;
                } catch (e: unknown) {
                    // eslint-disable-next-line no-console,no-restricted-globals
                    console.error(e);
                }
                // Default message is for unexpected returns. For example from load balancer etc...
                enqueueSnackbar(errorMessage, { variant: 'error' });
                return;
            }
        };
    }, [apiUrl, auth, enqueueSnackbar, selectedRestaurantId, t]);
};

export const useDownloadImage = () => {
    const { apiUrl, auth } = useAppContext();
    const [, selectedRestaurantId] = useSelectedRestaurantId();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');
    return useMemo(() => {
        const headers: Record<string, string> = { };
        if (auth !== null) {
            headers.Authorization = `Bearer ${auth.accessToken}`;
        }
        if (selectedRestaurantId !== null) {
            headers['id-restaurant'] = selectedRestaurantId;
        }
        return async (url: string): Promise<undefined | Blob> => {
            const response = await axios.get(`${apiUrl}${url}`, {
                headers,
                responseType: 'blob',
                validateStatus: () => true,
            });
            if (response.status === 200) {
                return response.data;
            }
            // we want to show message from server !
            if (response.status >= 300) {
                let errorMessage = t('error.message.unexpected');
                try {
                    const data = await (response.data as Blob).text();
                    const parsedData = JSON.parse(data);
                    errorMessage = parsedData.message ?? errorMessage;
                } catch (e: unknown) {
                    // eslint-disable-next-line no-console,no-restricted-globals
                    console.error(e);
                }
                // Default message is for unexpected returns. For example from load balancer etc...
                enqueueSnackbar(errorMessage, { variant: 'error' });
                return;
            }
        };
    }, [apiUrl, auth, enqueueSnackbar, t, selectedRestaurantId]);
};

export const useQerkoQuery = <
    TValidator extends z.ZodType<any, any>,
    TResult extends z.infer<TValidator>,
>(
    key: QueryKey,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: () => string | null,
    validator: TValidator,
    config: Omit<UseQueryOptions<TResult>, 'queryFn' | 'queryKey'> & {postRequestHandler?: (result: Result<Response<z.infer<TValidator>>, ApiClientRequestFailedError | ApiClientInvalidResponseDataError | ApiClientRequestCanceledError>, originalHandler: () => Response<TResult>) => Response<TResult>} = {},
): UseQueryResult<TResult, unknown> => {
    const { apiUrl, auth, setAuth } = useAppContext();
    const [isReady, selectedRestaurantId] = useSelectedRestaurantId();
    const { lang } = useLanguageContext();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');

    return useQuery<TResult, unknown, TResult>(
        {
            queryKey: key,
            // @ts-expect-error
            queryFn: async ({ signal }) => {
                const result = await doQerkoRequest<TValidator>(auth, selectedRestaurantId, apiUrl, path, {
                    lang,
                    method,
                    signal,
                    validator,
                });

                const originalHandler: (() => Response<z.infer<TValidator>>) = () => {
                    if (result.isErr()) {
                        if (result.error instanceof ApiClientRequestCanceledError) {
                            // nothing
                        } else if (
                            result.error instanceof ApiClientInvalidResponseDataError
                            && result.error.response.statusCode === 401
                        ) {
                            setAuth(null);
                            enqueueSnackbar(t('warning.message.sessionExpired'), { variant: 'warning' });
                        } else if (result.error instanceof ApiClientRequestFailedError) {
                            enqueueSnackbar(t('error.message.connectivity'), { variant: 'error' });
                        } else if (result.error.response.statusCode === 403) {
                            enqueueSnackbar(t('error.message.accessForbidden'), { variant: 'error' });
                        } else {
                            logger.error(result.error);
                            enqueueSnackbar(t('error.message.unexpected'), { variant: 'error' });
                        }

                        throw result.error;
                    }

                    // log validation error
                    if (result.value.statusCode === 400 && ['scope', 'query', 'params', 'data', 'result'].includes(result.value.body.type ?? '')) {
                        logger.error(createInlineError('ApiClient', 'Request validation error', {
                            extra: {
                                response: result.value.body,
                                statusCode: result.value.statusCode,
                                url: `${apiUrl}${path}`,
                            },
                        }));
                    }

                    return result.value;
                };

                if (config.postRequestHandler) {
                    return config.postRequestHandler(result, originalHandler);
                }

                return originalHandler();
            },
            ...config,
            enabled: config.enabled !== false && isReady,
        }
    );
};

export function useQerkoMutation<
    TValidator extends z.ZodType<any, any> = z.ZodUnknown,
    TInputValidator extends z.ZodType<any, any> = z.ZodVoid,
    TResult extends z.infer<TValidator> = undefined,
    TInput extends z.infer<TInputValidator> = void,
>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: (values: { body: TInput; auth: Auth | null }) => string,
    inputSchema: TInputValidator,
    validator: TValidator,
    options: UseMutationOptions<TResult, Error, TInput, unknown> & { apiUrl?: string } = {},
): UseMutationResult<TResult | undefined, unknown, TInput, unknown> {
    const { apiUrl, auth, setAuth } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');
    const router = useRouter();
    const [, selectedRestaurantId] = useSelectedRestaurantId();

    return useMutation<TResult | undefined, unknown, TInput>(
        {
            // @ts-expect-error strange bug
            mutationFn: async (body: TInput, headers?: Record<string, string>, lang?: string) => {
                const result = await doQerkoRequest<TValidator, TInput>(auth, selectedRestaurantId, options.apiUrl ?? apiUrl, path, {
                    body,
                    headers,
                    lang,
                    method,
                    validator,
                });

                if (result.isErr()) {
                    if (result.error instanceof ApiClientRequestCanceledError) {
                        // nothing
                    } else if (
                        result.error instanceof ApiClientInvalidResponseDataError
                        && result.error.response.statusCode === 401
                    ) {
                        // This should be here
                        router.replace({ pathname: '/login', query: { page: router.asPath } });
                        setAuth(null);
                        enqueueSnackbar(t('warning.message.sessionExpired'), { variant: 'warning' });
                    } else if (result.error instanceof ApiClientRequestFailedError) {
                        enqueueSnackbar(t('error.message.connectivity'), { variant: 'error' });
                    } else if (result.error.response.statusCode === 413) {
                        enqueueSnackbar(t('warning.message.tooLarge'), { variant: 'warning' });
                    } else if (result.error.response.statusCode === 403) {
                        enqueueSnackbar(t('error.message.accessForbidden'), { variant: 'error' });
                    } else {
                        logger.error(result.error);
                        enqueueSnackbar(t('error.message.unexpected'), { variant: 'error' });
                    }

                    return;
                }

                // log validation error
                if (result.value.statusCode === 400 && ['scope', 'query', 'params', 'data', 'result'].includes(result.value.body.type ?? '')) {
                    logger.error(createInlineError('ApiClient', 'Request validation error', { extra: {
                        response: result.value.body,
                        statusCode: result.value.statusCode,
                        url: `${options.apiUrl ?? apiUrl}${path}`,
                    } }));
                }

                return result.value;
            },
            ...options,
        },
    );
}

const fileValidator = GenerateSchema(z.object({
    fileId: z.string(),
}), 200);

export function useUploadFile<
    TValidator extends z.ZodType<any, any>,
    TResult extends z.infer<TValidator>,
>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: () => string,
    // @ts-expect-error
    validator: TValidator = fileValidator,
    options: UseMutationOptions<TResult, unknown, FormData, unknown> & { apiUrl?: string } = {},
): UseMutationResult<TResult | undefined, unknown, FormData, unknown> {
    const { apiUrl, auth, setAuth } = useAppContext();
    const [, selectedRestaurantId] = useSelectedRestaurantId();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');

    return useMutation<TResult | undefined, unknown, FormData>(
        {
            // @ts-expect-error strange bug
            mutationFn: async (body: TInput, headers?: Record<string, string>, lang?: string) => {
                const result = await doQerkoRequest<TValidator, FormData>(auth, selectedRestaurantId, options.apiUrl ?? apiUrl, path, {
                    body,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        ...headers,
                    },
                    lang,
                    method,
                    validator,
                });

                if (result.isErr()) {
                    if (result.error instanceof ApiClientRequestCanceledError) {
                        // nothing
                    } else if (
                        result.error instanceof ApiClientInvalidResponseDataError
                        && result.error.response.statusCode === 401
                    ) {
                        setAuth(null);
                        enqueueSnackbar(t('warning.message.sessionExpired'), { variant: 'warning' });
                    } else if (result.error instanceof ApiClientRequestFailedError) {
                        enqueueSnackbar(t('error.message.connectivity'), { variant: 'error' });
                    } else if (result.error.response.statusCode === 413) {
                        enqueueSnackbar(t('warning.message.tooLarge'), { variant: 'warning' });
                    } else if (result.error.response.statusCode === 403) {
                        enqueueSnackbar(t('error.message.accessForbidden'), { variant: 'error' });
                    } else {
                        logger.error(result.error);
                        enqueueSnackbar(t('error.message.unexpected'), { variant: 'error' });
                    }

                    return;
                }

                // we want to show message from server !
                if (result.value.statusCode >= 300) {
                    // Default message is for unexpected returns. For example from load balancer etc...
                    enqueueSnackbar((result.value.body as any).message ?? t('error.message.unexpected'), { variant: 'error' });
                    return;
                }

                return result.value;
            },
            ...options,
        },
    );
}

export const useQrRequest = <
    Method extends keyof APIv1Qr,
    Path extends keyof APIv1Qr[Method],
    // @ts-expect-error
    Result = APIv1Qr[Method][Path]['result'],
>(
    method: Method,
    path: Path,
    key: QueryKey,
    options: Omit<UseQueryOptions<Result>, 'queryFn' | 'queryKey'> & {
        // @ts-expect-error
        queryParams?: APIv1Qr[Method][Path]['params'];
        // @ts-expect-error
        query?: APIv1Qr[Method][Path]['query'];
        // @ts-expect-error
        data?: APIv1Qr[Method][Path]['data'];
    }
): UseQueryResult<Result, unknown> => {
    const { apiUrl } = useAppContext();

    return useQuery<Result, unknown, Result>(
        {
            queryKey: key,
            // @ts-expect-error
            queryFn: async () => {
                let resolvedPath: string = path.toString();
                for (const [ key, value ] of Object.entries(options.queryParams ?? {})) {
                    resolvedPath = resolvedPath.replace(':' + key, `${value}`);
                }

                if (typeof options?.query === 'object') {
                    const query = options?.query || {};
                    resolvedPath += '?';
                    resolvedPath += entries(query).map((i) => `${i[0]}=${encodeURIComponent(i[1] as string)}`).join('&');
                }

                try {
                    const response = await fetch(`${apiUrl}/api/v1/qr${resolvedPath}`);
                    const json = await response.json();

                    return {
                        httpStatus: response.status,
                        result: json,
                    };
                } catch (err: unknown) {
                    logger.error(err as Error);
                }
            },
            ...options,
        },
    );
};

export const useRestaurantAdminMutation = <
    Method extends keyof Omit<APIv1RestaurantAdmin, 'SUBSCRIPTION'>,
    Path extends keyof APIv1RestaurantAdmin[Method],
    // @ts-expect-error
    Result = APIv1RestaurantAdmin[Method][Path]['result'],
    // @ts-expect-error
    Data = APIv1RestaurantAdmin[Method][Path]['data'],
    // @ts-expect-error
    Query = IsUnknown<APIv1RestaurantAdmin[Method][Path]['query']> extends true ? undefined : APIv1RestaurantAdmin[Method][Path]['query'],
    // @ts-expect-error
    Params = IsUnknown<APIv1RestaurantAdmin[Method][Path]['params']> extends true ? undefined : APIv1RestaurantAdmin[Method][Path]['params'],
    Input = (undefined extends Data ? Record<never, never> : { data: Data }) & ({ headers?: Record<string, string> } | Record<never, never>) & (undefined extends Query ? Record<never, never> : { query: Query }) & (undefined extends Params ? Record<never, never> : { params: Params }),
>(
    method: Method,
    path: Path,
    options: Omit<UseMutationOptions<Result, unknown, Input, unknown>, 'mutationFn'> = {},
): UseMutationResult<Result | undefined, unknown, Input, unknown> => {
    const { apiUrl, auth, setAuth } = useAppContext();
    const [, selectedRestaurantId] = useSelectedRestaurantId();
    const { lang } = useLanguageContext();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');

    return useMutation<Result | undefined, unknown, Input>(
        {
            // @ts-expect-error
            mutationFn: async ({ data: body, params, query, headers: inputHeaders }: Input, headers?: Record<string, string>) => {
                const resolvedPath = getUrlWithParamsAndQueries(path.toString(), { params, query });
                const result = await doQerkoRequest(auth, selectedRestaurantId, apiUrl, () => `/api/v1/restaurant-admin${resolvedPath}`, {
                    body: body !== undefined ? superjson.serialize(body) as Record<string, any> : undefined,
                    headers: {
                        ['accept-language']: lang,
                        ['x-qerko-superjson']: '1',
                        ...(headers ?? {}),
                        ...(inputHeaders ?? {}),
                    },
                    lang,
                    method,
                    validator: z.any(),
                });

                if (result.isErr()) {
                    logger.error(result.error);

                    return;
                }

                // log validation error
                if (result.value.statusCode === 400 && ['scope', 'query', 'params', 'data', 'result'].includes(result.value.body.type ?? '')) {
                    logger.error(createInlineError('ApiClient', 'Request validation error', { extra: {
                        response: result.value.body,
                        statusCode: result.value.statusCode,
                        url: `/api/v1/restaurant-admin${resolvedPath}`,
                    } }));
                }

                // we want to show message from server !
                if (result.value.statusCode >= 300) {
                    if (result.value.statusCode === 401) {
                        setAuth(null);
                        enqueueSnackbar(t('warning.message.sessionExpired'), { variant: 'warning' });

                    } else if (result.value.statusCode === 413) {
                        enqueueSnackbar(t('warning.message.tooLarge'), { variant: 'warning' });

                    } else if (result.value.statusCode === 403) {
                        enqueueSnackbar(t('error.message.accessForbidden'), { variant: 'error' });

                    } else {
                        // Default message is for unexpected returns. For example from load balancer etc...
                        enqueueSnackbar(result.value.body.message ?? t('error.message.unexpected'), { variant: 'error' });
                    }

                    return;
                }

                return {
                    httpStatus: result.value.statusCode,
                    result: result.value.body,
                };
            },
            ...options,
        },
    );
};

export const useQrMutation = <
    Method extends keyof Omit<APIv1Qr, 'SUBSCRIPTION'>,
    Path extends keyof APIv1Qr[Method],
    // @ts-expect-error
    Result = APIv1Qr[Method][Path]['result'],
    // @ts-expect-error
    Data = APIv1Qr[Method][Path]['data'],
    // @ts-expect-error
    Query = IsUnknown<APIv1Qr[Method][Path]['query']> extends true ? undefined : APIv1Qr[Method][Path]['query'],
    // @ts-expect-error
    Params = IsUnknown<APIv1Qr[Method][Path]['params']> extends true ? undefined : APIv1Qr[Method][Path]['params'],
    Input = (undefined extends Data ? Record<never, never> : { data: Data }) & ({ headers?: Record<string, string> } | Record<never, never>) & (undefined extends Query ? Record<never, never> : { query: Query }) & (undefined extends Params ? Record<never, never> : { params: Params }),
>(
    method: Method,
    path: Path,
    options: UseMutationOptions<Result, unknown, Input, unknown> = {},
): UseMutationResult<Result | undefined, unknown, Input, unknown> => {
    const { apiUrl, auth } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');

    return useMutation<Result | undefined, unknown, Input>(
        {
            // @ts-expect-error
            mutationFn: async ({ data: body, params, query, headers: inputHeaders }: Input, headers?: Record<string, string>, lang?: string) => {
                const resolvedPath: string = getUrlWithParamsAndQueries(path.toString(), { params, query });
                const result = await doQerkoRequest(auth, null, apiUrl, () => `/api/v1/qr${resolvedPath}`, {
                    body,
                    headers: {
                        ...(headers ?? {}),
                        ...(inputHeaders ?? {}),
                    },
                    lang,
                    method,
                    validator: z.any(),
                });

                if (result.isErr()) {
                    if (result.error instanceof ApiClientRequestCanceledError) {
                        // nothing
                    } else if (result.error instanceof ApiClientRequestFailedError) {
                        enqueueSnackbar(t('error.message.connectivity'), { variant: 'error' });
                    } else if (result.error.response.statusCode === 413) {
                        enqueueSnackbar(t('warning.message.tooLarge'), { variant: 'warning' });
                    } else {
                        logger.error(result.error);
                        enqueueSnackbar(t('error.message.unexpected'), { variant: 'error' });
                    }

                    return;
                }

                // log validation error
                if (result.value.statusCode === 400 && ['scope', 'query', 'params', 'data', 'result'].includes(result.value.body.type ?? '')) {
                    logger.error(createInlineError('ApiClient', 'Request validation error', { extra: {
                        response: result.value.body,
                        statusCode: result.value.statusCode,
                        url: `/api/v1/restaurant-admin${resolvedPath}`,
                    } }));
                }

                // we want to show message from server !
                if (result.value.statusCode >= 300) {
                    // Default message is for unexpected returns. For example from load balancer etc...
                    enqueueSnackbar(result.value.body.message ?? t('error.message.unexpected'), { variant: 'error' });
                    return;
                }

                return {
                    httpStatus: result.value.statusCode,
                    result: result.value.body,
                };
            },
            ...options,
        },
    );
};

const generateCacheKey = ({ method, path, query, params, context }: {
    method: string;
    path: string;
    context?: Record<string, string | undefined> | undefined;
    query?: Record<string, string | undefined> | undefined;
    params?: Record<string, string | undefined> | undefined;
}) => ([
    `${method}:${path}`,
    {
        ...(query !== undefined ? Object.fromEntries(Object.entries(query).map(([key, value]) => ([ `query-${key}`, value ]))) : {}),
        ...(params !== undefined ? Object.fromEntries(Object.entries(params).map(([key, value]) => ([ `param-${key}`, value ]))) : {}),
        ...(context !== undefined ? Object.fromEntries(Object.entries(context).map(([key, value]) => ([ `context-${key}`, value ]))) : {}),
    },
]);

export const generateRestaurantAdminCacheKey = <
    Method extends keyof Omit<APIv1RestaurantAdmin, 'SUBSCRIPTION'>,
    Path extends keyof APIv1RestaurantAdmin[Method] & string,
>(method: Method, path: Path) => {
    return generateCacheKey({ method, path });
};

export const useRestaurantAdminQuery = <
    Method extends keyof Omit<APIv1RestaurantAdmin, 'SUBSCRIPTION'>,
    Path extends keyof APIv1RestaurantAdmin[Method] & string,
    // @ts-expect-error
    Result = APIv1RestaurantAdmin[Method][Path]['result'],
    // @ts-expect-error
    Query = IsUnknown<APIv1RestaurantAdmin[Method][Path]['query']> extends true ? undefined : APIv1RestaurantAdmin[Method][Path]['query'],
    // @ts-expect-error
    Params = IsUnknown<APIv1RestaurantAdmin[Method][Path]['params']> extends true ? undefined : APIv1RestaurantAdmin[Method][Path]['params'],
    // @ts-expect-error
    Data = IsUnknown<APIv1RestaurantAdmin[Method][Path]['data']> extends true ? undefined : APIv1RestaurantAdmin[Method][Path]['data'],
>(method: Method, path: Path, data: 'disabled' | Simplify<(undefined extends Query ? EmptyObject : { query: Query }) & (undefined extends Params ? EmptyObject : { params: Params }) & (undefined extends Data ? EmptyObject : { data: Data })>, options: Omit<UseQueryOptions<Result>, 'queryFn' | 'queryKey'> = {}): UseQueryResult<Result, unknown> & { queryKey: QueryKey } => {
    const { apiUrl, auth, setAuth } = useAppContext();
    const [isReady, selectedRestaurantId] = useSelectedRestaurantId();
    const { lang } = useLanguageContext();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');
    const resolvedPath = getUrlWithParamsAndQueries(
        path.toString(),
        data !== 'disabled' ? data : {},
    );

    const queryKey = generateCacheKey({
        context: {
            lang,
            restaurantId: selectedRestaurantId ?? undefined,
        },

        method,
        // @ts-expect-error
        params: 'params' in data ? data.params : undefined,

        path,
        // @ts-expect-error
        query: 'query' in data ? data.query : undefined,
    });

    return {
        queryKey,
        ...useQuery<Result, unknown, Result>(
            {
                queryKey,
                // @ts-expect-error
                queryFn: async ({ signal }) => {
                    const result = await doQerkoRequest(auth, selectedRestaurantId, apiUrl, () => `/api/v1/restaurant-admin${resolvedPath}`, {
                        body: data !== undefined && data !== 'disabled' && 'data' in data
                            ? data.data as Record<string, unknown>
                            : undefined,
                        headers: {
                            ['accept-language']: lang,
                            ['x-qerko-superjson']: '1',
                        },
                        method,
                        signal,
                        validator: z.any(),
                    });

                    if (result.isErr()) {
                        throw result.error;
                    }

                    // log validation error
                    if (result.value.statusCode === 400 && ['scope', 'query', 'params', 'data', 'result'].includes(result.value.body.type ?? '')) {
                        logger.error(createInlineError('ApiClient', 'Request validation error', { extra: {
                            response: result.value.body,
                            statusCode: result.value.statusCode,
                            url: `/api/v1/restaurant-admin${resolvedPath}`,
                        } }));
                    }

                    // we want to show message from server !
                    if (result.value.statusCode >= 300) {
                        if (result.value.statusCode === 401) {
                            setAuth(null);
                            enqueueSnackbar(t('warning.message.sessionExpired'), { variant: 'warning' });

                        } else if (result.value.statusCode === 413) {
                            enqueueSnackbar(t('warning.message.tooLarge'), { variant: 'warning' });

                        } else if (result.value.statusCode === 403) {
                            enqueueSnackbar(t('error.message.accessForbidden'), { variant: 'error' });

                        } else {
                        // Default message is for unexpected returns. For example from load balancer etc...
                            enqueueSnackbar(result.value.body.message ?? t('error.message.unexpected'), { variant: 'error' });
                        }

                        throw createInlineError('ApiClient', 'Unexpected statusCode', { extra: {
                            response: result.value.body,
                            statusCode: result.value.statusCode,
                            url: `/api/v1/restaurant-admin${resolvedPath}`,
                        } });
                    }

                    return {
                        httpStatus: result.value.statusCode,
                        result: result.value.body,
                    };
                },
                ...options,
                enabled: options.enabled !== false && isReady && data !== 'disabled',
            },
        ) };
};

export const useRestaurantAdminInfiniteQuery = <
    Method extends keyof Omit<APIv1RestaurantAdmin, 'SUBSCRIPTION'>,
    Path extends keyof APIv1RestaurantAdmin[Method] & string,
    // @ts-expect-error
    Result = APIv1RestaurantAdmin[Method][Path]['result'],
    // @ts-expect-error
    Query = IsUnknown<APIv1RestaurantAdmin[Method][Path]['query']> extends true ? undefined : APIv1RestaurantAdmin[Method][Path]['query'],
    // @ts-expect-error
    Params = IsUnknown<APIv1RestaurantAdmin[Method][Path]['params']> extends true ? undefined : APIv1RestaurantAdmin[Method][Path]['params'],
    // @ts-expect-error
    Data = IsUnknown<APIv1RestaurantAdmin[Method][Path]['data']> extends true ? undefined : APIv1RestaurantAdmin[Method][Path]['data'],
>(method: Method, path: Path, data: Simplify<(undefined extends Query ? EmptyObject : { query: Query }) & (undefined extends Params ? EmptyObject : { params: Params }) & (undefined extends Data ? EmptyObject : { data: Data })>, options: Omit<UseQueryOptions<Result>, 'queryFn' | 'queryKey'> = {}): UseInfiniteQueryResult<Result, unknown> => {
    const { apiUrl, auth, setAuth } = useAppContext();
    const [isReady, selectedRestaurantId] = useSelectedRestaurantId();
    const { lang } = useLanguageContext();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');

    return useInfiniteQuery<Result, unknown, Result>(
        {
            queryKey: generateCacheKey({
                context: {
                    lang,
                    restaurantId: selectedRestaurantId ?? undefined,
                },

                method,
                // @ts-expect-error
                params: 'params' in data ? data.params : undefined,

                path,
                // @ts-expect-error
                query: 'query' in data ? data.query : undefined,
            }),
            initialPageParam: undefined,
            // @ts-expect-error
            queryFn: async ({ signal, pageParam }) => {
                const resolvedPath = getUrlWithParamsAndQueries(
                    path.toString(),
                    {
                        ...data,
                        query: {
                            ...('query' in data && data.query !== undefined ? data.query : {}),
                            ...(pageParam !== undefined ? pageParam : {}),
                        },
                    },
                );

                const result = await doQerkoRequest(auth, selectedRestaurantId, apiUrl, () => `/api/v1/restaurant-admin${resolvedPath}`, {
                    body: data !== undefined && 'data' in data
                        ? superjson.serialize(data.data as Record<string, unknown>) as Record<string, any>
                        : undefined,
                    headers: {
                        ['accept-language']: lang,
                        ['x-qerko-superjson']: '1',
                    },
                    method,
                    signal,
                    validator: z.any(),
                });

                if (result.isErr()) {
                    throw result.error;
                }

                // log validation error
                if (result.value.statusCode === 400 && ['scope', 'query', 'params', 'data', 'result'].includes(result.value.body.type ?? '')) {
                    logger.error(createInlineError('ApiClient', 'Request validation error', { extra: {
                        response: result.value.body,
                        statusCode: result.value.statusCode,
                        url: `/api/v1/restaurant-admin${resolvedPath}`,
                    } }));
                }

                // we want to show message from server !
                if (result.value.statusCode >= 300) {
                    if (result.value.statusCode === 401) {
                        setAuth(null);
                        enqueueSnackbar(t('warning.message.sessionExpired'), { variant: 'warning' });

                    } else if (result.value.statusCode === 413) {
                        enqueueSnackbar(t('warning.message.tooLarge'), { variant: 'warning' });

                    } else if (result.value.statusCode === 403) {
                        enqueueSnackbar(t('error.message.accessForbidden'), { variant: 'error' });

                    } else {
                        // Default message is for unexpected returns. For example from load balancer etc...
                        enqueueSnackbar(result.value.body.message ?? t('error.message.unexpected'), { variant: 'error' });
                    }

                    throw createInlineError('ApiClient', 'Unexpected statusCode', { extra: {
                        response: result.value.body,
                        statusCode: result.value.statusCode,
                        url: `/api/v1/restaurant-admin${resolvedPath}`,
                    } });
                }

                return {
                    httpStatus: result.value.statusCode,
                    result: result.value.body,
                };
            },
            ...options,
            enabled: options.enabled !== false && isReady,
        },
    );
};
