import { clamp, maxBy, range } from 'lodash';
import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { parseTimeStringToHoursAndMinutes } from './util';

import type { ReservationDataCreateSchema } from './types';
import type { APIv1Qr } from 'src/shared-interface/ApiQrInterface';
import type { FormikType } from 'src/form-utils';
import type { z } from 'zod';
import type { PositiveInteger } from 'src/shared/types';

const MAX_SEATS = 20; // max seats in select

const getValuesForPersonsSelect = (slots: APIv1Qr['GET']['/reservation/slots']['result']['result']['slots']) => {
    if (slots.length === 0) return [];

    // get slot by max seats
    const slotByMaxSeats = maxBy(slots, (slot) => slot.seats);
    const maxSeats = clamp(slotByMaxSeats?.seats || 0, 1, MAX_SEATS);
    // clamp seats between 1 - MAX_SEATS
    return range(1, maxSeats + 1)
        .map((seat) => ({ label: `${seat}`, value: seat }));
};

const getValuesForTimeSelect = (seats: PositiveInteger, slots: APIv1Qr['GET']['/reservation/slots']['result']['result']['slots']) =>
    slots.reduce((result, slot) => {
        result.set(slot.id, {
            label: parseTimeStringToHoursAndMinutes(slot.start),
            note: slot.note,
            seats: slot.seats,
            value: slot.id,
        });

        return result;
    }, new Map<string, { label: string; note: string | null; seats: number; value: string }>());

export const ReservationBoxStep1SelectSeatsTime = (
    {
        slots,
        formik,
    }: {
        slots: APIv1Qr['GET']['/reservation/slots']['result']['result']['slots'];
        formik: FormikType<z.infer<typeof ReservationDataCreateSchema>>;
    }
) => {
    const { t } = useTranslation('restaurant.reservation');

    const isAllowedReservationForDay = slots.length !== 0;
    const timeValues = getValuesForTimeSelect(formik.values.seats, slots);
    const currentTimeValue = timeValues.get(formik.values.slotId);

    return (
        <>
            {!isAllowedReservationForDay && (
                <Box sx={{ color: '#F91F44', textAlign: 'center' }}>
                    <Typography>{t('restaurantDoesntAcceptNextReservationForToday')}</Typography>
                </Box>
            )}
            <TextField
                disabled={!isAllowedReservationForDay}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t('input.label.numberOfPeople')}
                select
                size="small"
                {...formik.selectNotNullProps('seats')}
                onChange={(event: React.ChangeEvent<any>) => {
                    formik.setFieldError('slotId', currentTimeValue !== undefined && currentTimeValue.seats < event.target.value ? t('slotHasInsufficientSeats') : undefined);
                    formik.setFieldValue(event.target.name, event.target.value);
                }}
            >
                {getValuesForPersonsSelect(slots).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                disabled={!isAllowedReservationForDay}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t('input.label.time')}
                select
                size="small"
                {...formik.selectNotNullProps('slotId')}
                error={formik.errors.slotId !== undefined}
                onChange={(event: React.ChangeEvent<any>) => {
                    const currentTimeValue = timeValues.get(event.target.value);
                    formik.setFieldError('slotId', currentTimeValue !== undefined && currentTimeValue.seats < event.target.value ? t('slotHasInsufficientSeats') : undefined);
                    formik.setFieldValue(event.target.name, event.target.value);
                }}
            >
                {Array.from(timeValues.values()).map((option) => (
                    <MenuItem
                        disabled={formik.values.seats > option.seats}
                        key={option.value}
                        sx={{ alignItems: 'flex-start', flexDirection: 'column' }}
                        value={option.value}
                    >
                        <Box>{option.label}</Box>
                        {formik.values.seats > option.seats && (
                            <Box>
                                <small>{t('slotHasInsufficientSeats')}</small>
                            </Box>
                        )}
                        {option.note !== null && (
                            <Box>
                                <small>{option.note}</small>
                            </Box>
                        )}
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
};
