import { UuidSchema } from 'src/shared/types';
import { useRouter } from 'next/router';

import type { Uuid } from 'src/shared/types';

export const useSelectedRestaurantId = (): [boolean, null] | [true, Uuid] => {
    const router = useRouter();
    if (!router.isReady) {
        return [false, null] as const;
    }

    const selectedRestaurantIdResult = UuidSchema.safeParse(router.query.selectedRestaurantId);

    return [true, selectedRestaurantIdResult.success ? selectedRestaurantIdResult.data : null] as const;
};
