import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { eachDayOfInterval, format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import cs from 'date-fns/locale/cs';
import { DateStringSchema } from 'src/shared/types';

import { ReservationBoxStep1SelectSeatsTime } from './ReservationBoxStep1SelectSeatsTime';
import { isButtonDisabledBoxStep1 } from './util';

import type { ReservationDataCreateSchema, SwitchState } from './types';
import type { APIv1Qr } from 'src/shared-interface/ApiQrInterface';
import type { ChangeEvent } from 'react';
import type { FormikType } from 'src/form-utils';
import type { z } from 'zod';

const generateValuesForDateSelect = (reservationDateInterval: APIv1Qr['GET']['/reservation/config']['result']['result']['reservationDateInterval']) => {
    return eachDayOfInterval({
        end: parse(reservationDateInterval.end, 'yyyy-MM-dd', new Date()),
        start: parse(reservationDateInterval.start, 'yyyy-MM-dd', new Date()),
    }).map((date) => ({ label: format(date, 'PP', { locale: cs }), value: format(date, 'yyyy-MM-dd') }));
};

export const ReservationBoxStep1 = ({
    slots,
    formik,
    reservationDateInterval,
    setSwitchState,
}: {
    slots: APIv1Qr['GET']['/reservation/slots']['result']['result']['slots'];
    formik: FormikType<z.infer<typeof ReservationDataCreateSchema>>;
    reservationDateInterval: APIv1Qr['GET']['/reservation/config']['result']['result']['reservationDateInterval'];
    setSwitchState: (switchState: SwitchState) => void;
}) => {
    const { t } = useTranslation('restaurant.reservation');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <TextField
                label={t('input.label.date')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (formik.values.date === e.target.value) {
                        return;
                    }

                    formik.setValues({
                        ...formik.values,
                        date: DateStringSchema.parse(e.target.value),
                        // @ts-expect-error
                        slotId: null,
                    });
                }}
                select
                size="small"
                sx={{ width: '100%' }}
                value={formik.values.date}
            >
                {generateValuesForDateSelect(reservationDateInterval).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            <ReservationBoxStep1SelectSeatsTime
                formik={formik}
                slots={slots}
            />

            <Button
                disabled={isButtonDisabledBoxStep1(formik)}
                fullWidth
                onClick={() => {
                    setSwitchState('personalIdentification');
                }}
                variant="contained"
            >
                {t('button.continue')}
            </Button>
        </Box>
    );
};
