import Box from '@mui/material/Box';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormikFromForZod } from 'src/form-utils';
import {
    DateStringSchema,
    NonEmptyStringSchema,
    PositiveIntegerSchema,
    UuidSchema,
} from 'src/shared/types';
import { useRouter } from 'next/router';
import { format } from 'date-fns';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ReservationSubSource } from 'src/shared-interface/ApiQrInterface';
import {
    useQrMutation,
    useQrRequest,
} from 'src/apiClient';
import { FullScreenLoader } from 'common/components/FullScreenLoader';

import { ReservationBoxStep1 } from './ReservationBoxStep1';
import { ReservationBoxStep2 } from './ReservationBoxStep2';
import { ReservationBoxStepSwitcher } from './ReservationBoxStepSwitcher';
import { isButtonDisabledBoxStep1 } from './util';
import { ReservationBoxWhiteWrap } from './ReservationBoxWhiteWrap';
import { ReservationBoxFooter } from './ReservationBoxFooter';
import { ReservationDataCreateSchema } from './types';

import type { Uuid,
    NonEmptyString } from 'src/shared/types';
import type { SwitchState } from './types';

export const ReservationBox = (
    {
        restaurantId,
    }: {
        restaurantId: Uuid;
    }) => {
    const router = useRouter();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const reservationSubSourceParam = router.query.subSource;

    const { mutateAsync: submit } = useQrMutation(
        'POST',
        '/reservation/create',
    );

    const formik = useFormikFromForZod(ReservationDataCreateSchema, {
        initialValues: {
            date: DateStringSchema.parse(format(new Date(), 'yyyy-MM-dd')),
            note: null,
            reservationSubSource: null,
            restaurantId: UuidSchema.parse(restaurantId),
            seats: PositiveIntegerSchema.parse(2),
            slotId: null,
            user: {
                email: null,
                name: null,
                phone: NonEmptyStringSchema.parse('+420 '),
            },
        },
        onSubmit: async (values) => {
            if (!executeRecaptcha) return;
            const tokenResult = await executeRecaptcha('submitReservation') as NonEmptyString;

            const value = await submit({
                data: {
                    captchaVerificationToken: tokenResult,
                    note: values.note,
                    reservationSubSource: typeof reservationSubSourceParam === 'string' && reservationSubSourceParam === 'seznam' ? ReservationSubSource.Seznam : null,
                    restaurantId,
                    seats: values.seats,
                    slotId: values.slotId,
                    user: {
                        email: values.user.email,
                        name: values.user.name,
                        phone: values.user.phone,
                    },
                },
            });
            if (value === undefined) return;

            if (value.httpStatus === 200) {
                await router.push(`/${restaurantId}/reservation/${value.result.reservation.id}`);
            }
        },
        validate: async (values) => {
            const errors: Record<string, string> = { };
            // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
            // eslint-disable-next-line
            const phoneValidation = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            if (!phoneValidation.test(values.user.phone.replaceAll(' ', ''))) {
                errors['user.phone'] = 'Vyplňte telefon';
            }
            return errors;
        },
    });

    const { data: reservationDateInterval } = useQrRequest(
        'GET',
        '/reservation/config',
        ['/reservation/config'],
        {
            query: { restaurantId },
        }
    );

    useEffect(() => {
        if (reservationDateInterval === undefined) {
            return;
        }

        const { start } = reservationDateInterval.result.reservationDateInterval;
        formik.setFieldValue('date', start);
        // eslint-disable-next-line
    }, [reservationDateInterval?.result.reservationDateInterval.start]);

    const { data: responseReservationSlots } = useQrRequest(
        'GET',
        '/reservation/slots',
        [`/reservation/slots/${formik.values.date}`],
        { query: { date: formik.values.date, restaurantId } }
    );

    const [switchState, setSwitchState] = useState<SwitchState>('reservation');
    const isDisableNextStep = isButtonDisabledBoxStep1(formik);

    return (
        <ReservationBoxWhiteWrap>
            <FullScreenLoader componentOrShow={
                reservationDateInterval && responseReservationSlots && (
                    <>
                        <Box sx={{ p: '40px' }}>
                            <form onSubmit={formik.handleSubmit}>
                                <ReservationBoxStepSwitcher isDisableNextStep={isDisableNextStep} onSwitch={setSwitchState} switchState={switchState} />
                                {switchState === 'reservation' && (
                                    <ReservationBoxStep1
                                        formik={formik}
                                        reservationDateInterval={reservationDateInterval.result.reservationDateInterval}
                                        setSwitchState={setSwitchState}
                                        slots={responseReservationSlots.result.slots}
                                    />
                                )}
                                {switchState === 'personalIdentification' && (<ReservationBoxStep2 formik={formik} />)}
                            </form>
                        </Box>
                        <ReservationBoxFooter includeSeznam={typeof reservationSubSourceParam === 'string' && reservationSubSourceParam === 'seznam'} />
                    </>
                )
            }
            />
        </ReservationBoxWhiteWrap>
    );
};
