import type { NonEmptyString, NonEmptyString255, Email, Phone, Uuid, DateString, NonNegativeInteger, PositiveInteger, NonNegativeFloat, DateTimeString, TimeString } from '../shared/types';

export interface APIv1Qr {
    GET: {
        '/restaurant/by-id/:restaurantId': {
            params: {
                restaurantId: string;
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        restaurant: {
                            id: Uuid | null;
                            isReservationAllowed: boolean | null;
                            isTablePairedToRestaurant: boolean | null;
                            isTagPairedToTable: boolean | null;
                            restaurantImage: string | null;
                            restaurantLogo: string | null;
                            restaurantName: string | null;
                            tableTagMode: TableTagMode | null;
                        };
                    };
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        '/restaurant/by-table-tag/:id/:namespace': {
            params: {
                id: string;
                namespace: string;
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        restaurant: {
                            id: Uuid | null;
                            isReservationAllowed: boolean | null;
                            isTablePairedToRestaurant: boolean | null;
                            isTagPairedToTable: boolean | null;
                            restaurantImage: string | null;
                            restaurantLogo: string | null;
                            restaurantName: string | null;
                            tableTagMode: TableTagMode | null;
                        };
                    };
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        '/restaurant/by-eshop-order/:id': {
            params: {
                id: string;
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        restaurant: {
                            id: Uuid | null;
                            isReservationAllowed: boolean | null;
                            isTablePairedToRestaurant: boolean | null;
                            isTagPairedToTable: boolean | null;
                            restaurantImage: string | null;
                            restaurantLogo: string | null;
                            restaurantName: string | null;
                            tableTagMode: TableTagMode | null;
                        };
                    };
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        '/reservation/slots': {
            query: {
                restaurantId: Uuid;
                date: DateString;
            };
            result: {
                httpStatus: 200;
                result: {
                    note: NonEmptyString | null;
                    slots: Array<{
                        id: NonEmptyString;
                        start: TimeString;
                        end: TimeString;
                        seats: NonNegativeInteger;
                        note: NonEmptyString | null;
                    }>;
                };
            };
        };
        '/reservation/config': {
            query: {
                restaurantId: Uuid;
            };
            result: {
                httpStatus: 200;
                result: {
                    reservationDateInterval: {
                        start: DateString;
                        end: DateString;
                    };
                };
            };
        };
        '/reservation/retrieve': {
            query: {
                reservationId: Uuid;
            };
            result: {
                httpStatus: 200;
                result: {
                    reservation: {
                        id: Uuid;
                        status: ReservationIipClientStatus;
                        rejectedReason: NonEmptyString | null;
                        reservationTime: DateTimeString | null;
                        seats: NonNegativeInteger;
                        user: {
                            name: NonEmptyString;
                            phone: Phone;
                            email: Email;
                        };
                        note: NonEmptyString | null;
                        restaurant: {
                            id: Uuid;
                            address: string;
                            name: NonEmptyString;
                            phone: Phone | null;
                            coordinates: {
                                latitude: NonNegativeFloat;
                                longitude: NonNegativeFloat;
                            };
                        };
                    };
                };
            };
        };
    };
    POST: {
        '/reservation/create': {
            data: {
                restaurantId: Uuid;
                slotId: NonEmptyString;
                seats: PositiveInteger;
                captchaVerificationToken: NonEmptyString;
                user: {
                    name: NonEmptyString255;
                    phone: NonEmptyString;
                    email: Email;
                };
                note: NonEmptyString255 | null;
                reservationSubSource?: ReservationSubSource | null | undefined;
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        reservation: {
                            id: Uuid;
                            status: ReservationIipClientStatus;
                            rejectedReason: NonEmptyString | null;
                            reservationTime: DateTimeString | null;
                            seats: NonNegativeInteger;
                            user: {
                                name: NonEmptyString;
                                phone: Phone;
                                email: Email;
                            };
                            note: NonEmptyString | null;
                            restaurant: {
                                id: Uuid;
                                address: string;
                                name: NonEmptyString;
                                phone: Phone | null;
                                coordinates: {
                                    latitude: NonNegativeFloat;
                                    longitude: NonNegativeFloat;
                                };
                            };
                        };
                    };
                } | {
                    httpStatus: 403;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        '/reservation/cancel': {
            data: {
                reservationId: Uuid;
            };
            result: {
                httpStatus: 200;
                result: {
                    reservation: {
                        id: Uuid;
                        status: ReservationIipClientStatus;
                        rejectedReason: NonEmptyString | null;
                        reservationTime: DateTimeString | null;
                        seats: NonNegativeInteger;
                        user: {
                            name: NonEmptyString;
                            phone: Phone;
                            email: Email;
                        };
                        note: NonEmptyString | null;
                        restaurant: {
                            id: Uuid;
                            address: string;
                            name: NonEmptyString;
                            phone: Phone | null;
                            coordinates: {
                                latitude: NonNegativeFloat;
                                longitude: NonNegativeFloat;
                            };
                        };
                    };
                };
            };
        };
    };
    PUT: {
    };
    DELETE: {
    };
    SUBSCRIPTION: {
    };
}

export enum TableTagMode {
    STATIC_TABLE = 'STATIC_TABLE',
    DIRECT_BILL = 'DIRECT_BILL',
}

export enum ReservationIipClientStatus {
    Waiting = 'waiting',
    Confirmed = 'confirmed',
    Rejected = 'rejected',
    Canceled = 'canceled',
}

export enum ReservationSubSource {
    Seznam = 'seznam',
    Google = 'google',
}
