import Box from '@mui/material/Box';
import * as React from 'react';
import { format, parse } from 'date-fns';
import { useRouter } from 'next/router';
import { useRestaurantAdminQuery } from 'src/apiClient';
import { NonEmptyStringSchema, UuidSchema } from 'src/shared/types';
import { FullScreenLoader } from 'common/components/FullScreenLoader';

import { RestaurantMenuDaily } from './RestaurantMenuDaily';

import type { DateString } from 'src/shared/types';
import type { RestaurantQrSchema } from 'src/shared/restaurantTypes';
import type { z } from 'zod';

export const RestaurantMenuDailyBox = (
    {
        restaurant,
    }: {
        restaurant: z.infer<typeof RestaurantQrSchema>['restaurant'];
    }) => {
    const router = useRouter();

    const {
        dailyMenuDate = format(new Date(), 'yyyy-MM-dd'),
    } = router.query;

    const dailyMenuDateAsDate = parse(dailyMenuDate as string, 'yyyy-MM-dd', new Date());

    const { data: restaurantMenuResult } = useRestaurantAdminQuery(
        'GET',
        '/menu/menu-data-for-pdf',
        {
            query: {
                from: format(dailyMenuDateAsDate, 'yyyy-MM-dd') as DateString,
                restaurantId: UuidSchema.parse(restaurant.id),
                to: format(dailyMenuDateAsDate, 'yyyy-MM-dd') as DateString,
                useDailyMenu: NonEmptyStringSchema.parse('true'),
                usePermanentMenu: NonEmptyStringSchema.parse('true'),
            },
        }
    );

    if (!restaurantMenuResult) return <FullScreenLoader componentOrShow={false} />;

    return (
        <Box sx={{
            backgroundColor: 'white',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            p: '1rem',
            width: '650px',
        }}
        >
            <RestaurantMenuDaily
                listOfDailyMenu={restaurantMenuResult.result.dailyMenuData}
            />
        </Box>

    );
};
