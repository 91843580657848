export const getUrlWithParamsAndQueries = (path: string, { params, query }: { params?: Record<string, string> | undefined; query?: Record<string, string> | undefined } = {}): string => {
    let resolvedPath: string = path + '?';
    for (const [ key, value ] of Object.entries(params ?? {})) {
        resolvedPath = resolvedPath.replace(':' + key, `${value}`);
    }
    const searchQuery: string[] = [];
    for (const [ key, value ] of Object.entries(query ?? {})) {
        if (Array.isArray(value)) {
            value.forEach((value) => {
                searchQuery.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`);
            });
        } else {
            searchQuery.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
    }
    return resolvedPath + searchQuery.join('&');
};
